html {}

body {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.main-grid {
    display: grid;
    width: 100vw;
    height: 100vh;
}

.background-div .content-div {
    
}

.background-div {
    grid-column: 1;
    grid-row: 1;
    
    z-index: 1;
    min-height: 100%;
    width: 100%;

    background-image: url(../img/img.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.background-div.blurry {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    transform: scale(1.1);
}

.content-div {
    grid-column: 1;
    grid-row: 1;

    z-index: 1000;

    overflow: auto;
    padding-top: 120px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.terminal-div {

    position:fixed;
    bottom:0px;
    margin:auto;
    height:160px;
    width:100%;
}

.xterm .xterm-viewport {
    overflow-y: hidden;
    width:100%;
}

@media screen and (max-width: 600px) {
    .img-logo {
        max-width: 300px;
    }
}